<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main class="space-y-10">
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Google 快速登入</h1>

              <vs-switch color="success" v-model="loginActive" :disabled="!google_clientid">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="w-full">
              <h2 class="title">Google OAuth2.0 用戶端ID</h2>
              <div class="relative">
                <ValidationProvider name="Google OAuth2.0 用戶端ID" :rules="`${loginActive ? 'required|max:200' : ''}`" v-slot="{ errors }">
                  <vs-input v-model="google_clientid" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive1 = !teachingActive1"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive1">
                <template #header>
                  <h3 class="text-lg">Google 快速登入 教學</h3>
                </template>
                <div class="space-y-2 text-sm">
                  <p>必須有google cloud platform帳號才可以進行操作</p>
                  <br />
                  <h5 class="text-base">步驟一</h5>
                  <p>API和服務->憑證</p>
                  <img class="w-full" src="@/assets/images/teaching/google14.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟二</h5>
                  <p>建立憑證->oauth 用戶端ID</p>
                  <img class="w-full" src="@/assets/images/teaching/google15.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟三</h5>
                  <p>填入所需資料</p>
                  <img class="w-full" src="@/assets/images/teaching/google16.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟四</h5>
                  <p>憑證->右邊編輯</p>
                  <img class="w-full" src="@/assets/images/teaching/google18.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟五</h5>
                  <p>找到用戶端編號->填入網站後台</p>
                  <img class="w-full" src="@/assets/images/teaching/google17.jpg" alt="" />
                  <br />

                  <br />
                </div>
              </vs-dialog>
            </div>
          </section>

          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Google Analytics</h1>

              <vs-switch color="success" v-model="gaActive" :disabled="!(google_gid4 || google_gid)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">評估 ID（新版GA4）</h2>
                <div class="relative">
                  <ValidationProvider name="評估 ID（新版GA4）" rules="max:100" v-slot="{ errors }">
                    <vs-input v-model="google_gid4" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full">
                <h2 class="title">追蹤 ID（舊版GA）</h2>
                <div class="relative">
                  <ValidationProvider name="追蹤 ID（舊版GA）" rules="max:100" v-slot="{ errors }">
                    <vs-input v-model="google_gid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive2 = !teachingActive2"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive2">
                <template #header>
                  <h3 class="text-lg">Google Analytics 教學</h3>
                </template>
                <div class="space-y-2 text-sm">
                  <br />
                  <h5 class="text-base">步驟一</h5>
                  <p>左下設定->建立帳戶</p>
                  <img class="w-full" src="@/assets/images/teaching/google19.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟二</h5>
                  <p>選擇帳戶->資料串流</p>
                  <img class="w-full" src="@/assets/images/teaching/google20.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟三</h5>
                  <p>網站->新增串流->選擇網站</p>
                  <img class="w-full" src="@/assets/images/teaching/google02.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟四</h5>
                  <p>填入所需資料</p>
                  <img class="w-full" src="@/assets/images/teaching/google03.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟五</h5>
                  <p>資料串流->點選資料</p>
                  <img class="w-full" src="@/assets/images/teaching/google04.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟六</h5>
                  <p>右上評估ID->填入網站後台</p>
                  <img class="w-full" src="@/assets/images/teaching/google05.jpg" alt="" />
                  <br />

                  <br />
                </div>
              </vs-dialog>
            </div>
            <!-- 教學文件 -->
          </section>

          <section class="bg-white rounded-2xl p-4 space-y-4" style="display: none">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Google Ads</h1>

              <vs-switch color="success" v-model="adsActive" :disabled="!(google_adsid || google_adsbuy)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">轉換ID</h2>
                <div class="relative">
                  <ValidationProvider name="轉換ID" rules="max:300" v-slot="{ errors }">
                    <vs-input v-model="google_adsid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full">
                <h2 class="title">轉換標籤-購買</h2>
                <div class="relative">
                  <ValidationProvider name="轉換標籤-購買" rules="max:300" v-slot="{ errors }">
                    <vs-input v-model="google_adsbuy" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive3 = !teachingActive3"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive3">
                <template #header>
                  <h3 class="text-lg">Google Ads 教學</h3>
                </template>
                <div class="space-y-2 text-sm">
                  <br />
                  <h5 class="text-base">步驟一</h5>
                  <p>工具與設定->轉換</p>
                  <img class="w-full" src="@/assets/images/teaching/google06.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟二</h5>
                  <p>新增轉換動作</p>
                  <img class="w-full" src="@/assets/images/teaching/google07.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟三</h5>
                  <p>選擇網站</p>
                  <img class="w-full" src="@/assets/images/teaching/google08.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟四</h5>
                  <p>填寫所需資料->建立並繼續</p>
                  <img class="w-full" src="@/assets/images/teaching/google09.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟五</h5>
                  <p>選擇google代碼管理工具</p>
                  <img class="w-full" src="@/assets/images/teaching/google11.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟六</h5>
                  <p>找到轉換ID與轉換標籤->填入網站後台</p>
                  <img class="w-full" src="@/assets/images/teaching/google10.jpg" alt="" />
                  <br />
                  <br />
                </div>
              </vs-dialog>
            </div>
            <!-- 教學文件 -->
          </section>

          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Google Tag Manager</h1>

              <vs-switch color="success" v-model="tamActive" :disabled="!google_tamid">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="w-full">
              <h2 class="title">GTM ID</h2>
              <div class="relative">
                <ValidationProvider name="GTMID" rules="max:100" v-slot="{ errors }">
                  <vs-input v-model="google_tamid" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive4 = !teachingActive4"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive4">
                <template #header>
                  <h3 class="text-lg">Google Tag Manager 教學</h3>
                </template>
                <div class="space-y-2 text-sm">
                  <br />
                  <h5 class="text-base">步驟一</h5>
                  <p>請先申請google代碼管理工具帳戶</p>
                  <p>選擇帳戶->管理->找到GTM ID->填入網站後台</p>
                  <img class="w-full" src="@/assets/images/teaching/google12.jpg" alt="" />
                  <br />
                  <br />
                </div>
              </vs-dialog>
            </div>
            <!-- 教學文件 -->
          </section>

          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      google_clientid: '',
      google_gid: '',
      google_gid4: '',
      google_adsid: '',
      google_adsbuy: '',
      google_tamid: '',
      loginActive: false,
      gaActive: false,
      adsActive: false,
      tamActive: false,
      teachingActive1: false,
      teachingActive2: false,
      teachingActive3: false,
      teachingActive4: false,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getGoogleData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.google_login = data.google_login
          this.google_ga = data.google_ga
          this.google_ads = data.google_ads
          this.google_tam = data.google_tam
          this.google_clientid = data.google_clientid
          this.google_gid = data.google_gid
          this.google_gid4 = data.google_gid4
          this.google_adsid = data.google_adsid
          this.google_adsbuy = data.google_adsbuy
          this.google_tamid = data.google_tamid

          data.google_login === 1 ? (this.loginActive = true) : (this.loginActive = false)
          data.google_ga === 1 ? (this.gaActive = true) : (this.gaActive = false)
          data.google_ads === 1 ? (this.adsActive = true) : (this.adsActive = false)
          data.google_tam === 1 ? (this.tamActive = true) : (this.tamActive = false)
        } else {
          console.error(res.data.Message)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        google_login: this.google_login,
        google_ga: this.google_ga,
        google_ads: this.google_ads,
        google_tam: this.google_tam,
        google_clientid: this.google_clientid,
        google_gid: this.google_gid,
        google_gid4: this.google_gid4,
        google_adsid: this.google_adsid,
        google_adsbuy: this.google_adsbuy,
        google_tamid: this.google_tamid,
      }

      this.$axios({
        url: 'front/set/app/uGoogleData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
  computed: {
    google_login: {
      get() {
        return this.loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    google_ga: {
      get() {
        return this.gaActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    google_ads: {
      get() {
        return this.adsActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    google_tam: {
      get() {
        return this.tamActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    google_clientid: function () {
      if (this.google_clientid === '') {
        this.loginActive = false
      }
    },
    google_gid4: function () {
      if (this.google_gid4 === '' && this.google_gid === '') {
        this.gaActive = false
      }
    },
    google_gid: function () {
      if (this.google_gid4 === '' && this.google_gid === '') {
        this.gaActive = false
      }
    },
    google_adsid: function () {
      if (this.google_adsid === '' && this.google_adsbuy === '') {
        this.adsActive = false
      }
    },
    google_adsbuy: function () {
      if (this.google_adsid === '' && this.google_adsbuy === '') {
        this.adsActive = false
      }
    },
    google_tamid: function () {
      if (this.google_tamid === '') {
        this.tamActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}
</style>
